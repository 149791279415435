import api from "./api.js";
import store from "@/store";
class SpellCheck {
  constructor() {
    this.url = "/api/SpellCheck";
  }
  runSpellCheck(words, userId = store.state.currentUser.id, labId = store.state.currentLab) {
    return api.post(this.url, { words, userId, labId });
  }
  addWordsToDictionary(words, userId = store.state.currentUser.id, labId = store.state.currentLab) {
    return api.post(this.url + "/AddWords", { words, userId, labId });
  }
  removeWordsFromDictionary(
    words,
    userId = store.state.currentUser.id,
    labId = store.state.currentLab
  ) {
    return api.post(this.url + "/RemoveWords", { words, userId, labId });
  }
  createUserDictionary(words, userId = store.state.currentUser.id, labId = store.state.currentLab) {
    return api.post(this.url + "/CreateUserDictionary", { words, userId, labId });
  }
  deleteUserDictionary(userId = store.state.currentUser.id, labId = store.state.currentLab) {
    return api.post(this.url + "/DeleteUserDictionary", { userId, labId });
  }
  getUserWordList(userId = store.state.currentUser.id, labId = store.state.currentLab) {
    return api.post(this.url + "/GetUserWordList", { userId, labId });
  }
}
export default new SpellCheck();
