import CustomStore from "devextreme/data/custom_store";
import MentionModule from "devextreme/ui/html_editor/modules/mentions";
import { isString, isPlainObject } from "lodash";
import Store from "@/store";
import ArrayStore from "devextreme/data/array_store";
import { MacroTypeEnum } from "../enums";
import localforage from "localforage";
export const regularExpressions = {
  group: /(\.\.([a-z|\s]))$/i,
  single: /([.|\\])([\w\-+]+)/i,
  specimen: /((?:([.|\\])[\w+-]+)+),([\w*>=<]+)/gi,
  block: /((?:[.]?[a-zA-Z0-9-]+)+),(=?[0-9a-zA-Z*><=]{1,3}),([0-9a-zA-Z*><=]+)/i
};

const USER_ACTION = "user";
const KEYS = {
  ARROW_UP: "upArrow",
  ARROW_DOWN: "downArrow",
  ARROW_LEFT: "leftArrow",
  ARROW_RIGHT: "rightArrow",
  ENTER: "enter",
  ESCAPE: "escape",
  SPACE: "space",
  PAGE_UP: "pageUp",
  PAGE_DOWN: "pageDown",
  END: "end",
  HOME: "home"
};

const ALLOWED_PREFIX_CHARS = [" ", "\n", `\\`];

const NAVIGATION_KEYS = [
  KEYS.ARROW_LEFT,
  KEYS.ARROW_RIGHT,
  KEYS.PAGE_UP,
  KEYS.PAGE_DOWN,
  KEYS.END,
  KEYS.HOME
];

const macroDatabase = localforage.createInstance({
  name: "IP_PRO",
  storeName: "macroDB",
  description: "Macro database"
});

export const macroStore = () => {
  return new CustomStore({
    async load(options = {}) {
      const isAllowedToResult = Store.getters.permissions.CaseFieldEditDiagnosis;
      let userId = Store.state.currentUser.id;
      const SegregateResultsMacros =
        Store.state.applicationSettings.segregateResultsMacros !== null
          ? Store.state.applicationSettings.segregateResultsMacros
          : Store.state.labSettings.SegregateResultsMacros;
      const primaryPathologist = Store.getters["accessionStore/primaryPathologist"];
      if (primaryPathologist?.guid && isAllowedToResult) {
        userId = primaryPathologist.guid;
      }
      const targetData = await macroDatabase.getItem(userId);
      const targetStore = new ArrayStore({
        data: targetData
      });
      if (options.searchValue) {
        options = {
          ...options,
          filter: ["displayName", "startswith", options.searchValue]
        };
      }
      if (SegregateResultsMacros) {
        if (options.filter?.length) {
          options.filter = [options.filter, "and", ["!", ["macroType", MacroTypeEnum.Results]]];
        } else {
          options.filter = ["!", ["macroType", MacroTypeEnum.Results]];
        }
      }
      return targetStore.load(options);
    },
    key: "id"
  });
};

class MacroModule extends MentionModule {
  constructor(quill, options) {
    super(quill, options);
    options.editorInstance.isMentionsOpen = false;
    this._popup.on("hidden", () => {
      options.editorInstance.isMentionsOpen = false;
    });
    this._popup.on("shown", () => {
      options.editorInstance.isMentionsOpen = true;
    });
  }

  _isMarkerPartOfText(retain) {
    if (!retain || ALLOWED_PREFIX_CHARS.indexOf(this._getCharByIndex(retain - 1)) !== -1) {
      return false;
    }
    return true;
  }
  _attachKeyboardHandlers() {
    this.quill.keyboard.addBinding(
      {
        key: KEYS.ARROW_UP
      },
      this._moveToItem.bind(this, "prev")
    );

    this.quill.keyboard.addBinding(
      {
        key: KEYS.ARROW_DOWN
      },
      this._moveToItem.bind(this, "next")
    );

    this.quill.keyboard.addBinding(
      {
        key: KEYS.SPACE,
        shiftKey: true
      },
      this._selectItemNameHandler.bind(this)
    );
    this.quill.keyboard.addBinding(
      {
        key: KEYS.SPACE
      },
      () => {
        if (this._isMentionActive) {
          this._popup.hide();
        }
        return true;
      }
    );

    this.quill.keyboard.addBinding(
      {
        key: KEYS.ENTER,
        shiftKey: false
      },
      this._selectItemNameHandler.bind(this)
    );

    const enterBindings = this.quill.keyboard.bindings[KEYS.ENTER];
    enterBindings.unshift(enterBindings.pop());

    this.quill.keyboard.addBinding(
      {
        key: KEYS.ESCAPE
      },
      this._escapeKeyHandler.bind(this)
    );

    this.quill.keyboard.addBinding(
      {
        key: [KEYS.ARROW_LEFT, KEYS.ARROW_RIGHT],
        shiftKey: true
      },
      this._ignoreKeyHandler.bind(this)
    );

    this.quill.keyboard.addBinding(
      {
        key: NAVIGATION_KEYS
      },
      this._ignoreKeyHandler.bind(this)
    );
  }
  onTextChange(newDelta, oldDelta, source) {
    if (source === USER_ACTION) {
      const lastOperation = newDelta.ops[newDelta.ops.length - 1];
      if (this._isMentionActive && this._isPopupVisible) {
        const nextActiveMention = this._mentions[lastOperation.insert];
        if (lastOperation.insert === " ") {
          this._popup.hide();
          this._searchValue = "";
          this._macroPhrase = "";
          this._search("");
        } else if (this._searchValue?.length && nextActiveMention) {
          this.checkMentionRequest(lastOperation);
          this._resetFilter();
        } else {
          this._processSearchValue(lastOperation) && this._filterList(this._searchValue);
        }
      } else {
        const { ops } = newDelta;
        const lastInsertOperation = this._getLastInsertOperation(ops);
        if (lastInsertOperation) {
          this.checkMentionRequest(lastInsertOperation, ops);
        }
      }
    }
  }

  _processSearchValue(operation) {
    const isInsertOperation = "insert" in operation;
    if (isInsertOperation) {
      this._macroPhrase += operation.insert;
      this._searchValue += operation.insert;
    } else {
      if (!this._searchValue.length || operation.delete > 1) {
        if (this._macroPhrase?.length > 1 && this._lastActiveMentionConfig) {
          this._filterList(this._lastSearch);
          this.checkMentionRequest({ insert: this._lastActiveMentionConfig.marker });
          this._searchValue = this._lastSearch;
          this.savePosition(this._lastCaretPosition);
          this._lastSearch = "";
        } else {
          this._popup.hide();
        }
        this._lastActiveMentionConfig = null;
        this._lastSearch = "";
        this._lastCaretPosition = null;
        return false;
      } else {
        this._searchValue = this._searchValue.slice(0, -1);
        this._macroPhrase = this._macroPhrase.slice(0, -1);
      }
    }

    return true;
  }
  _isMinSearchLengthExceeded(searchValue) {
    return searchValue.length >= (this._activeMentionConfig?.minSearchLength || 1);
  }
  checkMentionRequest({ insert }) {
    const caret = this.quill.getSelection();
    if (!insert || !isString(insert) || !caret) {
      return;
    }
    const requestedMention = this._mentions[insert];
    if (requestedMention?.isExtension) {
      if (!this._searchValue) {
        return;
      }
      this._lastActiveMentionConfig = this._activeMentionConfig;
      this._lastSearch = this._searchValue;
      this._lastCaretPosition = this.getPosition();
    }
    this._activeMentionConfig = requestedMention;
    if (this._activeMentionConfig) {
      if (this._activeMentionConfig.marker === ".") {
        // Don't open macro assist if character before period is part of a word.
        const previousCharacter = this.quill.getText(caret.index - 2, 1);
        if (/\w/.test(previousCharacter)) {
          return;
        }
      }
      this._updateList(this._activeMentionConfig);
      this.savePosition(caret.index);
      this._popup.option("position", this._popupPosition);
      this._searchValue = "";
      this._popup.show();
    }
  }

  _filterList(searchValue) {
    if (!this._isMinSearchLengthExceeded(searchValue)) {
      this._resetFilter();
      return;
    }

    const searchTimeout = 500;

    if (searchTimeout) {
      clearTimeout(this._searchTimer);
      this._searchTimer = setTimeout(() => {
        this._search(searchValue);
      }, searchTimeout);
    } else {
      this._search(searchValue);
    }
  }
  get SegregateResultsMacros() {
    return Store.state.applicationSettings.segregateResultsMacros !== null
      ? Store.state.applicationSettings.segregateResultsMacros
      : Store.state.labSettings.SegregateResultsMacros;
  }

  insertItemName(itemName) {
    if (isPlainObject(itemName)) {
      const valueExpr =
        this._activeMentionConfig?.valueExpr + this.SegregateResultsMacros ? " " : "";

      if (valueExpr) {
        itemName = itemName[valueExpr];
      }
    }
    const SPACE_EVENT = { key: " ", keyCode: 32, code: "Space" };
    const marker = this._activeMentionConfig.marker;
    const markerLength = 1;
    const textLength = this._searchValue.length;
    const caretPosition = this.getPosition();
    let startIndex = Math.max(0, caretPosition - markerLength);
    const retainCorrection = this._getCharByIndex(startIndex) === "\n" ? 1 : 0;
    startIndex += retainCorrection;
    const format = this.quill.getFormat(startIndex);
    if (this._searchValue.toLowerCase() !== itemName.toLowerCase()) {
      this.quill.deleteText(startIndex, textLength + markerLength);
      this.quill.insertText(startIndex, marker + itemName, format);
      this.quill.setSelection(startIndex + itemName.length + markerLength, 0);
    }
    if (this.SegregateResultsMacros) {
      this.quill.root.dispatchEvent(new KeyboardEvent("keydown", SPACE_EVENT));
    }
    this._searchValue = "";
    this._popup.hide();
  }
  getCaretPosition() {
    const caret = this.quill.getSelection();
    return caret?.index;
  }

  insertEmbedContent() {
    let selectedItem = this._list.option("selectedItem");
    this.insertItemName(selectedItem);
  }
  _selectItemNameHandler() {
    if (this._isMentionActive) {
      this.insertItemName(this._list.option("focusedElement").innerText);
    }
    return !this._isMentionActive;
  }
  _selectItemHandler() {
    if (this._isMentionActive) {
      this._list.selectItem(this._list.option("focusedElement"));
    }
    return !this._isMentionActive;
  }
}

export default MacroModule;
